function deepCopy(o) {
  if (o == null) {
    return o;
  }
  return JSON.parse(JSON.stringify(o));
}
function deepCompare(o1, o2) {
  if (o2 == null) {
    if (o1 == null) {
      return o2 === o1;
    }
    return false;
  }
  return JSON.stringify(o1) === JSON.stringify(o2);
}

/**
 * Generated bundle index. Do not edit.
 */

export { deepCompare, deepCopy };
